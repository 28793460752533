<template>
	<div id="manyStoreWithdraw">
		<div class="el-content">
			<div class="tips">
				<p>提示：</p>
				<p>1、冻结金额需等到用户确认收货后方可提现。</p>
				<p>2、使用微信渠道提现，请确保客户端已与微信绑定。</p>
				<p>3、若未绑定微信请到客户端绑定微信后再使用微信提现。</p>
			</div>
			<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
				<a-form-item label="账户余额">
					<span>可提现金额：
						<span class="kd-withdraw-price">{{bank.bank}}</span>元
						<span class="kd-withdraw-all" @click="withdrawAll">[全部提现]</span>
					</span>
					<span style="margin-left: 20px;">冻结金额：
						<span class="kd-withdraw-price">{{bank.frozen}}</span>元
					</span>
					<span style="margin-left: 20px;">待结算金额：
						<span class="kd-withdraw-price">{{bank.wait_settled}}</span>元
						<span class="kd-withdraw-tag">提示：冻结金额需等到用户确认收货后方可提现</span>
					</span>
					
				</a-form-item>
				<a-form-item label="提现金额">
					<a-input v-model:value="form.price"></a-input>
				</a-form-item>
				<a-form-item label="提现方式" >
					<div class="kd-paymethod">
						<div class="kd-paymethod-li" :class="[form.method==0?'kd-p-active':'']" @click="form.method=0">
							<i class="iconfont icon-zhifubaozhifu"  style="color: #2196F3;"></i>支付宝支付
							<img v-if="form.method==0" class="active-img-alipay" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
						<div class="kd-paymethod-li" :class="[form.method==1?'kd-p-active':'']" @click="form.method=1">
							<i class="iconfont icon-weixinzhifu" style="color: #4CAF50;"></i> 微信支付
							<img v-if="form.method==1" class="active-img" src="@/assets/img/icon-share-selected.png" alt="">
						</div>
					</div>
				</a-form-item>
				<div v-if="form.method ==0">
					<a-form-item label="真实姓名">
						<a-input v-model:value="form.name"></a-input>
					</a-form-item>
					<a-form-item label="支付宝账号">
						<a-input v-model:value="form.account"></a-input>
					</a-form-item>
				</div>
				<a-form-item :wrapper-col="{offset:2}">
					<a-button type="primary" @click="submitSave">提交</a-button>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import shopModel from '@/api/addons/shop'
import tool from '@/util/tool'
export default{
	setup(){
		const state = reactive({
			form:{
				type:2,
				name:'',
				price:'',
				method:0,
				account:'',
			},
			bank:[],
		})

		getGlod()

		function getGlod(){
			shopModel.getShopUserDetail(res=>{
				shopModel.getShopMoney(res.shop.shop_id,res=>state.bank = res)
			})
		}
		
		function withdrawAll(){
			if( state.bank.bank <=0 ||  !state.bank.bank ){
				tool.message("余额不足~","warning")
				return
			}
			state.form.price = state.bank.bank
		}

		function submitSave(){
			let { form,bank } = state
			if( parseFloat(bank.bank) < parseFloat(form.price)){
				tool.message("余额不足","warning")
				return
			}
			shopModel.shopWithdraw(form,()=>getGlod())
		}

		return{
			...toRefs(state),
			withdrawAll,
			submitSave
		}
	}
}
</script>

<style lang="scss">
	.kd-withdraw{
		&-price{
			color: red;
			font-size: 20px;
			font-weight: bold;
		}
		
		&-all{
			color: #0074D9;
			cursor: pointer;
		}
		
		&-tag{
			color: #868686;
			font-size: 12px;
			margin-left: 10px;
		}
	}
	.kd-paymethod{
		display: flex;
		
		&-li{
			width: 150px;
			height: 50px;
			font-size: 18px;
			cursor: pointer;
			line-height: 50px;
			margin-right: 10px;
			text-align: center;
			border: 1px solid #e4e4e4;
			
			.iconfont{
				font-size: 22px;
				margin-right: 5px;
			}
			
			.active-img{
				position: absolute;
				width: 24px;
				height: 20px;
				margin-top: 27px;
				margin-left: 11px;
			}
			.active-img-alipay{
				position: absolute;
				width: 24px;
				height: 20px;
				margin-top: 27px;
				margin-left: 3px;
			}
		}
		
		.kd-p-active{
			border: 2px solid #ff4544;
		}
	}
</style>
